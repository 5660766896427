<template>
  <div
    v-if="isLoading"
    class="loading"
  >
    <img
      src="@/assets/logo-emp.png"
      class="img-fluid rotate"
      alt="Logo loading"
      width="150"
    >
  </div>
</template>

<script>
  export default {
    name: 'Load',
    computed: {
      isLoading () {
        return this.$store.getters.splashScreen
      },
    },
  }
</script>

<style scoped>
.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

.loading {
  position: fixed;
  z-index: 1031;
  height: 200px;
  width: 200px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
}

</style>
