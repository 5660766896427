<template>
  <div>
    <load />
    <router-view />
  </div>
</template>

<script>
  import Load from './components/Loading'
  export default {
    name: 'App',
    components: {
      Load,
    },
    mounted: function () {
      this.$store.commit('setSplashScreen', false)
    },
  }

</script>
