// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { firestorePlugin } from 'vuefire'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import './registerServiceWorker'
import VuetifyNumber from 'vuetify-number'
import VueSweetalert2 from 'vue-sweetalert2'
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'
import VueCurrencyInput from 'vue-currency-input'
import AnimateCSS from 'animate.css'
import * as VeeValidate from 'vee-validate'
import VueApexCharts from 'vue-apexcharts'
import firebase from 'firebase'

import Vuelidate from 'vuelidate'
import VueMobileDetection from 'vue-mobile-detection'

import VueQuagga from 'vue-quaggajs'
// import firebaseMessaging from './firebase'

Vue.use(VueMobileDetection)

Vue.use(Vuelidate)

Vue.use(AnimateCSS)
Vue.use(VueCurrencyInput)

Vue.use(VueSweetalert2)

Vue.use(VuetifyNumber)

Vue.use(VueTheMask)
Vue.use(money)
Vue.use(VeeValidate)
Vue.use(firebase)
// CHARTS
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueQuagga)

Vue.config.productionTip = false

var firebaseConfig = {
  apiKey: 'AIzaSyDXqlLLTcnokP-KDZT1Y06ffXFBQXwzlr8',
  authDomain: 'sf-api-11859.firebaseapp.com',
  databaseURL: 'https://sf-api-11859.firebaseio.com',
  projectId: 'sf-api-11859',
  storageBucket: 'sf-api-11859.appspot.com',
  messagingSenderId: '574363731770',
  appId: '1:574363731770:web:332e7d303b29f77cd7f203',
  measurementId: 'G-09M6BDS7NT',
}

firebase.initializeApp(firebaseConfig)

// Vue.prototype.$messaging = firebaseMessaging

Vue.use(firestorePlugin)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
